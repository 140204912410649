<template>
  <v-card flat class="ma-3">

    <InfosCandidat ref="infoscandidat"></InfosCandidat>
    <UpdateCandidat ref="updatecandidat" @candidatUpdated="fetchCandidats()"></UpdateCandidat>
    <DelCandidat ref="delcandidat" @candidatDeleted="fetchCandidats()"></DelCandidat>

    <v-card height="110" class="elevation-3 rounded-xl pa-2 mb-3" flat color="#FFFEFE">

      <v-card class="d-flex flex-row mb-3" flat>

        <!-- Barre de recherche -->
        <v-text-field v-model="search" clearable background-color="#f4f4f4" class="rounded-pill mr-2" dense hide-details
          single-line label="Rechercher" outlined rounded>

          <template v-slot:append>
            <v-card @click="fetchCandidats()" flat class="pa-1 rounded-circle pos-button" color="#1b6b9a">
              <v-icon color="white">mdi-magnify</v-icon>
            </v-card>
          </template>
        </v-text-field>


        <v-btn-toggle v-model="toggle" mandatory rounded dense class="rounded-pill" color="mc2i" @change="changeToggle()">

          <v-btn value="OPE">OPE</v-btn>
          <v-btn value="FCT">FCT</v-btn>

        </v-btn-toggle>

      </v-card>

      <v-card class="d-flex flex-row align-center" flat>


        <v-chip-group :key="key" v-model="selectedFilterChip" mandatory>
          <v-menu v-model="showChipMenu" :close-on-content-click="false" transition="fab-transition" rounded="xl"
            max-width="256" offset-y bottom>
            <template>
              <v-chip v-for="filter in activeFilters" :key="filter.text" class="rounded-pill">
              </v-chip>
            </template>
          </v-menu>
        </v-chip-group>

        <v-btn @click="exportData" :loading="exporting" :disabled="exporting" class="floating-export-btn" color="#0F9D58" v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(3) || userRoles.includes(4) || userRoles.includes(6) || userRoles.includes(7) || userRoles.includes(8) || userRoles.includes(9) || userRoles.includes(10)" rounded small fab>
          <v-icon color="white">mdi-google-spreadsheet</v-icon>
        </v-btn>

      </v-card>
    </v-card>

    <v-data-table class="justify-center elevation-4 rounded-xl grey lighten-3" :items="candidats"
      :server-items-length="totalItems" :loading="loading" :server-search="true" :server-pagination="true"
      :items-per-page.sync="page_size" :page.sync="page" :server-items="fetchCandidats" :footer-props="{
        'itemsPerPageOptions': [250, 500, 750, 1000],
        'showCurrentPage': true,
        'show-first-last-page': true,
        'items-per-page-text': 'Rows per page :',
        'rows-per-page-text': 'Rows'
      }" no-data-text="Aucun candidat trouvé" no-results-text="Aucun candidat trouvé"
      loading-text="Chargment des candidats...">
      <template v-slot:header="header">
        <thead>
          <tr>
            <th v-for="header in headers" class="pa-2 text-center">
              {{ header.text }}
              <v-menu :close-on-content-click="false" rounded="xl" class="d-block" rigth offset-x>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on">{{ shownFilterIcons(header.value) }}</v-icon>
                </template>

                <v-list>
                    <v-date-picker v-if="header.value == candidatsFilters[0].value" :first-day-of-week="1" v-model="candidatsFilters[0].selected"
                    no-title multiple range locale="fr-FR" width="228" @input="handleDateRangeChange"><v-chip variant="outlined" @click="clearDate(0)" class="mx-auto">Réinitialiser filtre</v-chip></v-date-picker>

                </v-list>

                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[1].value" flat>
                  <v-checkbox class="ma-0" v-for="profilsFCT in champs.profilsFCT" v-model="candidatsFilters[1].selected"
                    :key="profilsFCT" :value="profilsFCT" :label="profilsFCT"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[2].value" flat>
                  <v-checkbox class="ma-0" v-for="label_entite in champs.label_entite"
                    v-model="candidatsFilters[2].selected" :key="label_entite" :value="label_entite"
                    :label="label_entite"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[3].value" flat>
                  <v-checkbox class="ma-0" v-for="pole in champs.poles" v-model="candidatsFilters[3].selected" :key="pole"
                    :value="pole" :label="pole"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[4].value" flat>
                  <v-checkbox class="ma-0" v-for="necole in champs.nom_ecole" v-model="candidatsFilters[4].selected"
                    :key="necole" :value="necole" :label="necole"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[5].value" flat>
                  <v-checkbox class="ma-0" v-for="tecole in champs.types_ecole" v-model="candidatsFilters[5].selected"
                    :key="tecole" :value="tecole" :label="tecole"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[6].value" flat>
                  <v-checkbox class="ma-0" v-for="desistement in champs.desistement"
                    v-model="candidatsFilters[6].selected" :key="desistement" :value="desistement"
                    :label="desistement"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                  v-if="header.value == candidatsFilters[7].value" flat>
                  <v-checkbox class="ma-0" v-for="mdesistement in champs.motif_desistement"
                    v-model="candidatsFilters[7].selected" :key="mdesistement" :value="mdesistement"
                    :label="mdesistement"></v-checkbox>
                </v-card>
                <v-card max-height="300" max-width="250" class="overflow-auto ps-3 pe-3"
                    v-if="header.value == candidatsFilters[8].value" flat>
                      <v-checkbox class="ma-0" v-for="annee_desistement in customYears" v-model="candidatsFilters[8].selected"
                        :value="annee_desistement" :label="annee_desistement"></v-checkbox>
                    </v-card>

              </v-menu>

            </th>
          </tr>
        </thead>
      </template>

      <!--Couleur du candidat-->
      <template v-slot:item="{ item }">
        <tr class="grey lighten-2">
          <td>{{ dateEnToFr(item.date_desistement) }}</td>
          <td>{{ item.date_desistement ? new Date(item.date_desistement).getFullYear() : '' }}</td>
          <td>{{ item.nom }}</td>
          <td>{{ item.prenom }}</td>
          <td>{{ item.profilFCT }}</td>
          <td>{{ item.label_entite }}</td>
          <td>{{ item.pole }}</td>
          <td>{{ item.nom_ecole }}</td>
          <td>{{ item.type_ecole }}</td>
          <td>{{ item.desistement }}</td>
          <td>{{ item.motif_desistement }}</td>
          <td>{{ item.info_desistement }}</td>

          <!--Bouton de gestion du candidat-->
          <td v-if="userRoles.includes(1) || userRoles.includes(2) || userRoles.includes(4) || userRoles.includes(5)">
            <v-menu rounded="xl" left offset-x>
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" icon>
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list rounded dense>
                <v-list-item @click="$refs.infoscandidat.showInfos(item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-information</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Informations</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.updatecandidat.openDialog(item.id, typeCandidat, item)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Modifier</v-list-item-title>
                </v-list-item>
                <v-list-item @click="$refs.delcandidat.openDialog(item.id, typeCandidat)">
                  <v-list-item-icon>
                    <v-icon class="pb-2">mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>Supprimer</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
          <td v-else>
            <v-icon>mdi-link-lock</v-icon>
          </td>  
        </tr>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import InfosCandidat from "../components/InfosCandidat";
import DelCandidat from "../components/form/DelCandidat";
import UpdateCandidat from "../components/form/UpdateCandidat";
import { desistementFCTHeaders } from "../TabHeaders/desistementFCTHeaders.js";
import { desistementsFCTFilters } from "../components/filters/desistementsFCTFilters.js";
import { getCandidats } from "../api/candidats";
import { rowColor, chipColor } from "../commonFunction/statusColors";
import { dateEnToFr, dateInText } from "../commonFunction/date";
import { getChamps } from "../api/champs";
import { exportToGSheet } from '../api/export'
import { customYears } from "../commonFunction/date";



export default {
  components: {
    InfosCandidat,
    DelCandidat,
    UpdateCandidat
  },
  data: () => ({
    typeCandidat: "fct",
    toggle: "FCT",
    exporting: false,
    item: {}, 
    headers: [], 
    candidatFCTHeaders: [], 
    search: "",
    selectedFilter: "",
    selectedFilterChip: "",
    candidatsFilters: [],
    candidats: [],
    filters: {},
    page_size: 250,
    loading: false,
    totalItems: 0,
    page: 1,
    perPage: 10,
    loading: false,
    showChipMenu: false,
    showListMenu: false,
    inactiveFilters: [],
    customYears: [],
    key: 0,
    tableKey: 0,
  }),
  created() {
    this.headers = desistementFCTHeaders; 
    this.candidatsFilters = desistementsFCTFilters
    this.inactiveFilters = desistementsFCTFilters
  },
  async mounted() {
    // Initialisation du tableau de candidats
    this.fetchCandidats()
    this.$store.commit("SET_CHAMPS", await getChamps())
    this.customYears = customYears()


  },
  computed: {
    shownFilterIcons() {
      return (header) => {
        const showIcon = this.showFilterIcon(header).length > 0;
        const showVariantIcon = this.showFilterIcon(header).length === 1 && !this.curentHeaderFilters(header).length > 0;
        return showVariantIcon ? 'mdi-filter-variant' : showIcon ? 'mdi-filter' : '';
      };
    },
    userRoles() {
      return this.$store.getters.currentRoles;
    },
    champs() {
      return this.$store.getters.champs
    },
    activeFilters() {
      if (!this.showChipMenu) {
        this.key += 1
      }
      return this.candidatsFilters.filter((f) => {
        this.filters = {
          type: "fct",
          statut: "Désistement",
          page: this.page,
          page_size: this.page_size,
          search: this.search,
          date_desistement: this.candidatsFilters[0].selected,
          profilFCT: this.candidatsFilters[1].selected,
          label_entite: this.candidatsFilters[2].selected,
          pole: this.candidatsFilters[3].selected,
          nom_ecole: this.candidatsFilters[4].selected,
          type_ecole: this.candidatsFilters[5].selected,
          desistement: this.candidatsFilters[6].selected,
          motif_desistement: this.candidatsFilters[7].selected,
          annee_desistement: this.candidatsFilters[8].selected,
        }
        return f.selected.length > 0
      })
    }
  },

  methods: {
    showFilterIcon(header) {
      return this.candidatsFilters.filter(elt => elt.value === header);
    },
    curentHeaderFilters(header) {
      return this.candidatsFilters.filter(elt => elt.value == header && elt.selected.length > 0)
    },

    async exportData() {
      try {
        this.exporting = true; 
        const email = this.$store.getters.user.email;
        const result = await exportToGSheet(email, this.filters)

        window.open(result.SheetURL, "_blank");
        
        //console.log('Exportation terminée!');
      } catch (error) {
        console.error('Erreur lors de l\'exportation vers Google sheet', error);
      } finally {
        this.exporting = false; 
      }
    },

    async fetchCandidats() {
      this.loading = true;

      // Sauvegarder la page actuelle avant de fetch les candidats
      const currentPage = this.page;

      await getCandidats(this.filters).then((response) => {
        this.candidats = response.items;
        this.totalItems = response.total;

        // Vérifier si la page actuelle est toujours valide après le filtrage
        if (currentPage > Math.ceil(this.totalItems / this.page_size)) {
          // Si la page actuelle n'est plus valide, revenir à la première page
          this.page = 1;
        }

        this.loading = false;
      });
    },
    removeFilter(filter) {
      for (let i = 0; i < this.candidatsFilters.length; i++) {
        if (this.candidatsFilters[i].value == filter) {
          this.candidatsFilters[i].selected = []
        }
      }
    },
    handleDateRangeChange() {
      const selectedDates = this.candidatsFilters[0].selected;

      const startDate = new Date(selectedDates[0]);
      const endDate = new Date(selectedDates[selectedDates.length - 1]);
      const allDates = this.getDatesBetween(startDate, endDate);

      this.candidatsFilters[0].selected = allDates;

      this.fetchCandidats();
    },
    clearDate(fieldIndex){
      const selectedDates = this.candidatsFilters[fieldIndex].selected;
      this.candidatsFilters[fieldIndex].selected = selectedDates.filter((item) => item == null);
    },
    // Fonction pour générer toutes les dates entre deux dates
    getDatesBetween(startDate, endDate) {
      const dates = [];
      let currentDate = startDate;
      while (currentDate <= endDate) {
        dates.push(currentDate.toISOString().split('T')[0]); // Format des dates ISO (YYYY-MM-DD)
        currentDate.setDate(currentDate.getDate() + 1);
      }
      return dates;
    },
    updateFilters() {
      //console.log("updateFilters triggered")
      this.inactiveFilters = this.candidatsFilters.filter((f) => {
        return f.selected.length == 0
      })
    },
    changeToggle() {
      this.$router.push('/desistements/ope')
    },
    dateEnToFr,
    dateInText,
    rowColor,
    chipColor,
  },
  watch: {
    showListMenu(newValue) {
      if (newValue == false) {
        this.updateFilters()
      }
    },
    showChipMenu(newValue) {
      if (newValue == false) {
        this.updateFilters()
      }
    },
    filters() {
      this.fetchCandidats()
    }
  }
};
</script>

<style scoped>
td {
  border: none;
  text-align: center;
}

ul {
  list-style-type: none;
  text-align: center;
}

.floating-export-btn {
  position: fixed;
  bottom: 15px;
  left: 70px;
  z-index: 1;
}

.pos-button {
  position: relative;
  top: -4px;
  left: 19px;
}
</style>